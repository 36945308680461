import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import DashboardLayout from "../../molecules/DashboardLayout";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Pagination,
  Card,
  Button,
  Menu,
  Space,
  Input,
  Dropdown,
  Select,
  Typography,
  message,
  Form,
  Tag,
} from "antd";
import SectionHeader from "../../molecules/SectionHeader";
import Grid from "../../atoms/Grid";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { getLeaseForms, visiblePopup, getLeadID } from "./ducks/actions";
import { PopupConfirm, Popup } from "../../atoms/Popup";
import CancelLeaseForm from "./cancelLease";
import {
  downloadLeaseForm,
  cancelLeaseForm,
} from "../../../utils/fileDownload";

import { baseContractService } from "../../../configs/constants";
import axios from "axios";
import { dateByformat } from "../../../utils/dateHelper";
import { currencyFormat, statusLabel } from "../../../utils/media";
import { useSearchHistory } from "../../../utils/filterHelper";
import { setFilterLease } from "../App/SearchFilters/ducks/actions";
const gridProps = {
  sortable: true,
  filter: true,
  resizable: true,
  minWidth: 220,
};
const dropDown = [
  { id: 1, name: "Download lease form", status: "#184461" },
  { id: 2, name: "Edit", status: "" },
  { id: 3, name: "Add Unit", status: "" },
  { id: 4, name: "Cancel", status: "#FB7171" },
];
const dropDown1 = [
  { id: 1, name: "Download lease form", status: "#184461" },
  { id: 2, name: "Edit", status: "" },
];

const dropDown2 = [
  { id: 1, name: "Download lease form", status: "#184461" },
  { id: 2, name: "Send for tenant's signature", status: "" },
  { id: 3, name: "Add Unit", status: "" },
  { id: 4, name: "View Approved Form", status: "" },
];

const searchKeys = [
  { value: "name", label: "Name" },
  { value: "unitCode", label: "Unit Number", default: true },
  { value: "property", label: "Property" },
  { value: "leaseExecutive", label: "Lease Executive" },
];



const getModifiedStatus = (param) => {
  const renewalleaseStatus = param?.data?.renewalleaseStatus;
  const renewalStatus = param?.data?.renewalStatus;
  const leaseStatus = param?.data?.leaseStatus;
  const isRenewing = param?.data?.isRenewing;




  if (renewalStatus || renewalleaseStatus) {

    //   after discussion with @vishwajeet if leaseStatus === active and renewalStatus === renewal_decline then show
  //  status active_vacating 
    
   if(leaseStatus === "active" && renewalStatus === "renewal_declined")   
    return (
      <Tag className="tag-sm" color="success">
        { statusLabel('active_vacating')}
      </Tag>
    );
   if(leaseStatus === "active" && renewalStatus === "renewal_accepted")
    
   return (
    <Tag className="tag-sm" color="success">
      {statusLabel('active_renewing')}
    </Tag>
     );
  
    if (renewalleaseStatus === "booking_cancelled") {
      return (
        <Tag className="tag-sm" color="warning">
          {statusLabel(renewalleaseStatus)}
        </Tag>
      );
    }
    // according to story number 8688
    if(isRenewing && leaseStatus == 'lease_expired' &&  renewalStatus == 'renewal_accepted'){
  
      return (
        <Tag className="tag-sm" color="success">
          {statusLabel(leaseStatus)}
        </Tag>
      );
    
    }
  
    // according to story number 8688
    if(isRenewing && leaseStatus == 'lease_expired' && renewalStatus == 'renewal_declined'){
      return (
        <Tag className="tag-sm" color="success">
          {statusLabel(leaseStatus)}
        </Tag>
      );
    } 
     
  
    if (
      renewalleaseStatus == "active_vacating" ||
      renewalleaseStatus == "booking_reject" 
    ) {
      return (
        <Tag className="tag-sm" color="warning">
          {/* icon={<ExclamationCircleOutlined />} */}
          {statusLabel("active_vacating")}
        </Tag>
      );
    } else if (renewalleaseStatus == "booking_cancelled") {
      //booking_cancelled scenario is new
      return (
        <Tag className="tag-sm" color="success">
          {/* icon={<ExclamationCircleOutlined />} */}
          {statusLabel(leaseStatus)}
        </Tag>
      );
    } else if (renewalleaseStatus == "renewal_sent") {
      //booking_cancelled scenario is new
      return (
        <Tag className="tag-sm" color="success">
          {statusLabel(leaseStatus)}
        </Tag>
      );
    }else {
      <Tag className="tag-sm" color="success">
      {statusLabel(leaseStatus)}
    </Tag>
    }

  } else {
    return (
      <Tag className="tag-sm" color="success">
        {/* icon={<CheckCircleOutlined />}  */}
        {statusLabel(leaseStatus)}
      </Tag>
    );
  }


};

const leadCol = [
  {
    headerName: "Status",
    field: "leaseStatus",
    minWidth: 250,
    cellRendererFramework: getModifiedStatus,
  },
  {
    headerName: "Property",
    field: "propertyDetails.propertyName",
    // minWidth: 320,
  },
  {
    headerName: "Unit Number",
    field: "propertyDetails.unitCode",
    minWidth: 320,
  },
  {
    headerName: "Tenant",
    field: "tenantDetails.tenantNameEN",
  },
  {
    headerName: "Annual Rent",
    field: "leaseTerms.contractAmount",
    cellRendererFramework: ({ data }) => {
      return (
        <div className="annualRent">
          {currencyFormat(data.leaseTerms.contractAmount)}
        </div>
      );
    },
  },
  {
    headerName: "Start Date",
    field: "leaseTerms.leaseStartDate",
    minWidth: 150,
    cellRendererFramework: ({ data }) => {
      return dateByformat(data.leaseTerms.leaseStartDate, "DD-MM-YYYY");
    },
  },
  {
    headerName: "End Date",
    field: "leaseTerms.leaseEndDate",
    minWidth: 150,
    cellRendererFramework: ({ data }) => {
      return dateByformat(data.leaseTerms.leaseEndDate, "DD-MM-YYYY");
    },
  },
  {
    headerName: "Contract Type",
    field: "leaseType",
    cellRendererFramework: ({ data }) => {
      return statusLabel(data.leaseType);
    },
  },
  {
    headerName: "AWB No",
    field: "shipmentNumber",
  },
  // {
  //   headerName: "Renewal Status",
  //   field: "renewalleaseStatus",
  //   minWidth: 180,
  //   cellRendererFramework: ({ data }) => data?.renewalleaseStatus ? data?.renewalleaseStatus :
  //     data?.isRenewing === true ? (<span style={{ color: "green" }}>Active Renewing</span>) : "—",
  // },
  // {
  //   // headerName: "Renewal Status",
  //   // field: "renewalleaseStatus",
  //   minWidth: 170,
  //   cellRendererFramework: ({ data }) => data?.isRenewing === true ? (<span style={{ color: "green" }}>Active Renewing</span>) : "—",
  // },
  // {
  //   headerName: "Created At",
  //   field: "createdAt",
  //   cellRendererFramework: dateFormat,
  // },
  {
    headerName: "Actions",
    field: "manage",
    cellRendererFramework: (row) => viewButton(row),
    pinned:'right',
    maxWidth:140
  },
];



const limit = 50;

const viewButton = (row) => {
  const navigate = useNavigate();
  const viewForm = () => {
    navigate(`/viewbookingform/${row.data.leaseId}`);
    // navigate(`/bookings/form/${row.data.leaseId}`, {
    //   state: { isDisabledAll: true, isLease: true },
    // });
  };

  return (
    <Button size="large" type="primary" onClick={viewForm}>
      View Lease
    </Button>
  );
};

const MoreAction = (row) => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const { Text } = Typography;
  const [selected, setSelected] = useState([]);
  const onChnageHanldler = async (e) => {
    if (e === "Cancel") {
      onStatus(row.data.id);
    } else if (e === "Add Unit") {
      dispatch(visiblePopup());
      dispatch(getLeadID(row.data.id));
    } else if (e === "Download lease form") {
      downloadLeaseForm(row.data.id);
    } else if (e === "View Approved Form") {
      navigate(
        `leads/${row.data.id}/lease-form/${row.data.assest_id}/${row.data.party_id}`
      );
    } else if (e === "Edit") {
      navigate(
        `leads/${row.data.id}/lease-form/${row.data.assest_id}/${row.data.party_id}`
      );
    } else if (e === "Send for tenant's signature") {
      try {
        let body = {
          leadId: row.data.id,
        };
        await axios.post(
          `${baseContractService}/lease-form-send-to-prospect`,
          body
        );
        message.success("Successfully Sent");
      } catch (e) {
        const { response } = e;
        message.error(response?.data?.message);
      }
    }
  };
  const onStatus = (leadID) => {
    PopupConfirm({
      title: `Are you sure you want to cancel`,
      onOk: () => onCancleLeaseForm(leadID),
      okText: "Yes",
    });
  };
  const onCancleLeaseForm = (leadID) => {
    cancelLeaseForm(leadID).then((response) => {
      if (response?.data?.message === "Success") {
        message.success(response?.data?.result);
      }
    });
  };

  return (
    <Select
      onChange={onChnageHanldler}
      className="Sentence"
      placeholder="Select an action"
      style={{ width: 150 }}
      allowClear
    >
      <>
        {row.data.status.toLowerCase().includes("draft") &&
          dropDown.map((value, key) => (
            <React.Fragment key={key}>
              <Select.Option value={value.name}>
                <Text style={{ color: `${value.status}` }}>{value.name}</Text>
              </Select.Option>
            </React.Fragment>
          ))}
        {(row.data.status.toLowerCase().includes("confirmed") ||
          row.data.status.toLowerCase().includes("opportunity_rejected")) &&
          dropDown1.map((value, key) => (
            <React.Fragment key={key}>
              <Select.Option value={value.name}>
                <Text style={{ color: `${value.status}` }}>{value.name}</Text>
              </Select.Option>
            </React.Fragment>
          ))}
        {row.data.status == "opportunity_approved" &&
          dropDown2.map((value, key) => (
            <React.Fragment key={key}>
              <Select.Option value={value.name}>
                <Text style={{ color: `${value.status}` }}>{value.name}</Text>
              </Select.Option>
            </React.Fragment>
          ))}
      </>
    </Select>
  );
};

const LeaseformList = (props) => {
  const dispatch: any = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const filter = useSelector((state: any) => state.filters.leasesData);
  const [searchBy, setSearchBy] = useState(
    Object.keys(filter)[0] ?? "unitCode"
  );
  const [search, setSearch] = useState(filter[Object.keys(filter)[0]] ?? "");

  const { searchHistory, addToSearchHistory } =
    useSearchHistory(setFilterLease);
  // const visible = useSelector((state: any) => state.leases.visiblePopup);
  const leaseformdata = useSelector((state: any) => state.leases.getLeaseForms);

  const onClosePopUp = () => {
    dispatch(visiblePopup());
  };

  const popupProps = {
    title: "Enter Unit Code",
    // visibility: visible,
    width: 650,
    content: <CancelLeaseForm closePopUp={onClosePopUp} />,
    onCancel: onClosePopUp,
  };

  const handlePagination = (pg) => {
    setCurrentPage(pg);
    dispatch(getLeaseForms(limit, pg, search, searchBy));
  };

 
  function onTopSearch(e) {
    if (e?.length === 0) setSearch("");
    else if (e?.length < 2)
      message.error("Please enter atleast two characters");
    else setSearch(e);
  }

  function onTopSearchBy(e) {
    setSearchBy(e?.value);
  }

  useEffect(() => {
    setCurrentPage(1);
    addToSearchHistory(searchBy, search); //adding search history and filter store
    setTimeout(() => {
      dispatch(getLeaseForms(limit, 1, search, searchBy));
    }, 500);
  }, [search, searchBy]);

  useEffect(() => {
    let key = Object.keys(searchHistory)[0];
    if (searchKeys.length > 0)
      searchKeys.map((item) => {
        if (key == item.value) item["default"] = true;
        else item["default"] = false;
        return item;
      });
   
  }, [searchHistory]);

  return (
    <Fragment>
      <DashboardLayout>
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <SectionHeader
                  heading="Leases"
                  //@ts-ignore
                  total={leaseformdata?.totalCount || 0}
                  search={(e) => onTopSearch(e)}
                  searchBy={(e) => onTopSearchBy(e)}
                  defaultSearch={search}
                  searchOptions={searchKeys}
                />
              </Col>
              <Col span={24}>
                <Pagination
                  className="ag-property-text-pagination"
                  total={leaseformdata?.totalCount || 0}
                  showTotal={(total, range) =>
                    `Showing ${range[0]}-${range[1]} of ${total} Leases`
                  }
                  showSizeChanger={false}
                  defaultPageSize={50} //leaseformdata?.perPage ||
                  defaultCurrent={1}
                  current={currentPage}
                  onChange={(e) => handlePagination(e)}
                />
              </Col>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="end">
                  <Col span={24}>
                    <Card
                      bordered={false}
                      className="ag-nospace-body"
                      // extra={<SearchFelds />}
                    >
                      <Grid
                        refCol="status"
                        //@ts-ignore
                        data={leaseformdata?.data ?? []}
                        columns={leadCol}
                        pagination={false}
                        defaultSettings={gridProps}
                        noRowlabel="Lease"
                        fullPage={true}
                      />
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupProps} />
    </Fragment>
  );
};

export default LeaseformList;
